<!--
 * @Date: 2020-12-29 11:17:53
 * @LastEditTime: 2020-12-29 11:31:21
 * @Author: zhangyang
 * @LastEditors: zhangyang
 * @Description: 404
 * @FilePath: \xkc_website\src\views\common\404.vue
-->
<template>
  <div class="error-page">
    <div class="error-code">
      4
      <span>0</span>
      4
    </div>
    <div class="error-desc">啊哦~ 你所访问的页面不存在</div>
  </div>
</template>

<script>
export default {
  methods: {}
};
</script>


<style scoped>
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #f3f3f3;
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.error-code {
  line-height: 1;
  font-size: 50px;
  font-weight: bolder;
  color: #2d8cf0;
}
.error-code span {
  color: #00a854;
}
.error-desc {
  font-size: 25px;
  color: #777;
}
.error-handle {
  margin-top: 30px;
  padding-bottom: 200px;
}
.error-btn {
  margin-left: 100px;
}
</style>
