<!--
 * @Date: 2020-12-29 11:17:32
 * @LastEditTime: 2020-12-29 12:22:45
 * @Author: zhangyang
 * @LastEditors: zhangyang
 * @Description: demo
 * @FilePath: \xkc_website\src\views\common\demo.vue
-->
<template>
  <div class="demo">

  </div>
</template>

<script>
export default {

}
</script>
<style scoped>

</style>