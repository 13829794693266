<!--
 * @Date: 2021-01-29 17:59:07
 * @LastEditTime: 2022-03-22 12:11:17
 * @Author: zhangyang
 * @LastEditors: ZhangYang
 * @Description: 合作商户
 * @FilePath: \portal\src\views\cooperation\index.vue
-->
<template>
    <div class="cooperation">
        <comp-head :navIndex="3" :bgColor="scrollTop>0?true:false" />
        <section class="content">
            <!-- <div class="header">
                <h1 class="font-style text-48">合作伙伴</h1>
            </div> -->
            <div class="cooperation_box">
                <div class="box">
                    <h2 class="font-style text-40">部分合作伙伴</h2>
                    <h3 class="not-width text-16">Partners</h3>
                    <div class="list flex align">
                        <img class="item" src="https://cdn2.xxkucun.com/portal/common/brands.png" alt="合作伙伴" ondragstart="return false;" oncontextmenu="return false;">
                    </div>
                </div>
            </div>
        </section>
        <comp-footer />
    </div>
</template>
<script>
export default {
    name:'Cooperation',
    data() {
        return {
            scrollTop:0,
        }
    },
    mounted() {
         window.addEventListener('scroll', this.windowScroll)
    },
    methods: {
        windowScroll() {
            this.scrollTop = document.documentElement.scrollTop
            }
        }
}
</script>
<style lang="less" scoped>
.cooperation{
    width: 100%;
    min-height: 100vh;
    color: #fff;
    .header {
        height: 100%;
        height: 4.2rem;
        background: url('https://cdn2.xxkucun.com/portal/home/home_banner_2_1.png') center 0 no-repeat;
        background-size: cover;
        line-height: 4.2rem;
    }
    .cooperation_box{
        width: 100%;
        color: #000;
        .box{
            padding-top: 56px;
            width: 8.8rem;
            margin: 0.96rem auto;
            .list{
                width: 100%;
                flex-wrap: wrap;
                margin-top: 0.2rem;
                box-sizing: border-box;
                padding: 0.2rem;
                .item{
                    width: 100%;
                    // >img{
                    //     width: 100%;
                    // }
                }
            }
        }
    }
    .font-style{
        letter-spacing: 0.05rem;
        font-family: PingFangSC-Semibold, PingFang SC;
    }
    .not-width{
        font-weight: 400;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: rgba(0, 0, 0, 0.65);
    }
}
    
</style>
