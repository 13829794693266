<!--
 * @Date: 2020-12-29 11:18:56
 * @LastEditTime: 2022-03-17 20:36:53
 * @Author: zhangyang
 * @LastEditors: ZhangYang
 * @Description: home
 * @FilePath: \portal\src\views\home\index.vue
-->
<template>
  <div class="home">
    <comp-head :navIndex="0" :bgColor="scrollTop>0?true:false" />
    <div class="home_box">
      <section class="content">
        <div class="banner_box">
          <div class="box flex between align">
            <div class="banner_left_box">
              <div class="banner_title">
                <div class="text-48 font-bold">本地生活数字化</div>
                <div class="text-48 font-bold">营销服务平台</div>
                <div class="text-18 font-bold" style="margin:12px 0;">"交易型Saas+服务"让商家开店更简单</div>
                <div class="text-16" >高效的帮助商家提升曝光纳客，</div>
                <div class="text-16" >助力餐饮企业实现数字化经营</div>
              </div>
              <!-- <div class="banner_btn">注册体验</div> -->
              <div class="banner_btn" @click="toPage">了解更多</div>
            </div>
            <img src="https://cdn2.xxkucun.com/portal/home/banner_right.png" alt="" ondragstart="return false;">
          </div>
        </div>
        <div class="content_img_box">
          <div class="img_box" v-for="(e,i) in 7" :key="i">
            <div class="img_box_bg" :class="e==2||e==4||e==6?'bg_1':'bg_2'">
              <div class="box">
                <img :src="`https://cdn2.xxkucun.com/portal/home/home_content_${e}.png`" alt="" ondragstart="return false;" oncontextmenu="return false;">
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <comp-footer />
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      scrollTop: 0
    }
  },
  mounted() {
    window.addEventListener('scroll', this.windowScroll)
  },
  methods:{
    windowScroll() {
      this.scrollTop = document.documentElement.scrollTop
    },
    toPage(){
      this.$router.push('my')
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  width: 100%;
}
.home_box {
  width: 100%;
  .content{
    .banner_box{
      width: 100%;
      height: 600px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      background: #FFF1F1;
      .box{
        max-width:1440px;
        margin: 0 auto;
        .banner_left_box{
          width: 50%;
          min-width: 40%;
          .banner_title{
            color: rgba(0, 0, 0, 0.85);
            text-align: left;
            font-family: MicrosoftYaHei-Bold;
            letter-spacing: 0em;
          }
          .banner_btn{
            cursor: pointer;
            margin-top:40px ;
            width: .8rem;
            height: 0.25rem;
            display: flex;
            flex-direction: column;
            padding: 12px 32px;
            border-radius: 4px;
            background: #FF163A;
            box-shadow: 0px 0.01px 0.02px 0px rgba(255, 22, 58, 0.25);
            font-family: MicrosoftYaHei-Bold;
            font-size: 20px;
            font-weight: 600;
            line-height: 0.25rem;
            text-align: center;
            letter-spacing: 0em;
            color: #fff;
          }
        }
      >img{
        max-width:60%;
        // height:544px;
      }
      }
    }
    .content_img_box{
      width: 100%;
      margin-bottom:80px;
      .img_box{
        width: 100%;
        .img_box_bg{
          width: 100%;
          .box{
            max-width: 1440px;
            margin: 0 auto;
            >img{
              width:100%;
            }
          }
        }
      }
      .bg_1{
          background: #FCF8F8;
      }
      .bg_2{
          background: #fff;
      }
    }
  }
}
</style>